module.exports = [
  {
    locale: 'pl',
    label: 'Polski',
  },
  {
    locale: 'en',
    label: 'English',
    default: true,
  },
];
